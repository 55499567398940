import React, { useEffect, useState } from 'react';

import Link from '@components/Link';
import Loading from '@components/Loading';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import EditSubscriptionModal from './EditSubscriptionModal';
import SubscriptionsResult from './SubscriptionsResult';

import {
  Countries,
  CreditCardCode,
  Currencies,
  editSubscriptionRecord,
  FilteredTitle,
  Fund,
  FundName,
  IndividualSecurityGroupsResult,
  SecGroupOutput,
  Subscriptions,
} from '@domain/my-donations/constants';

import { useNotifications } from '@use-cases/notifications';

import {
  useGetCountries,
  useGetCurrencies,
  useGetFunds,
  useGetIndividualSecurityGroups,
  useGetSubscriptionsForMember,
} from '@repositories/my-donations';
import { useFetchProfileDetails } from '@repositories/profile/hooks';

import { getTransformedSecurityGroups } from '@utils/getTransformedSecurityGroups';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const SubscriptionsList: React.FC = () => {
  const { user } = useAppConfig();
  const individualId = user?.individualId || '';
  const [subscriptionAPIError, setSubscriptionAPIError] = useState(false);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [subscriptionEditRecord, setSubscriptionEditRecord] = useState<
    editSubscriptionRecord | undefined
  >(undefined);

  const { t, i18n } = useTranslation();
  const [fundsData, setFundsData] = useState<Fund[]>([]);
  const [countriesData, setCountriesData] = useState<Countries[]>([]);
  const [currenciesData, setCurrenciesData] = useState<Currencies[]>([]);
  const [subscriptions, setSubscriptions] = useState<Subscriptions[]>([]);
  const [securityGroupsData, setSecurityGroupsData] = useState<
    SecGroupOutput[]
  >([]);
  type LanguageCode = 'en' | 'fr' | 'de' | 'es' | 'it' | 'ja' | 'ko' | 'pt';
  const linkForAdd = `/rotary-direct`;
  const [
    fetchSubscriptions,
    { data: subscriptionData, error: subscriptionsError },
  ] = useGetSubscriptionsForMember();

  const searchParams = new URLSearchParams(window.location.search);
  const transactionId = searchParams.get('epp_transaction_id');
  const rnwView = searchParams.get('rnw-view');

  const redirectUrl = `/my-donations`;
  const redirect = () => {
    localizedNavigate(redirectUrl);
  };
  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
  } = useFetchProfileDetails(individualId);

  const [fetchIndividualSecurityGroups] = useGetIndividualSecurityGroups();

  const [fetchAllFunds] = useGetFunds();
  const [fetchAllCountries] = useGetCountries();
  const [fetchAllCurrencies] = useGetCurrencies();
  const { addSuccess, clearNotification } = useNotifications();
  const msgToBeShownAfterModalClose = t(
    'edit-subscription-modal-info',
    "Changes to your recurring donation are being processed. When processing is finished, you'll receive a confirmation email. You will then be able to see your changes reflected here."
  );

  const netForumMemberId =
    profileData?.individual.riIndividualId?.toString() || '';

  useEffect(() => {
    if (transactionId && rnwView) {
      setIsEditModalOpen(true);
      const editSubscriptionRecord = window.localStorage.getItem(
        'editSubscriptionRecord'
      );
      if (editSubscriptionRecord !== null) {
        setSubscriptionEditRecord(JSON.parse(editSubscriptionRecord));
      }
    }
  }, []);

  const handleEditModalOpen = (
    editSubscriptionData: editSubscriptionRecord
  ) => {
    if (editSubscriptionData.subscriptionData) {
      clearNotification('subscription.edit-modal-info');
      setIsEditModalOpen(true);
      setSubscriptionEditRecord(editSubscriptionData);
      const editRecord = JSON.stringify(editSubscriptionData);
      window.localStorage.setItem('editSubscriptionRecord', editRecord);
    }
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    document.body.style.overflow = '';
    window.localStorage.removeItem('editSubscriptionRecord');
    if (transactionId && rnwView) {
      redirect();
      addSuccess(msgToBeShownAfterModalClose, {
        id: 'subscription.edit-modal-info',
      });
      if (netForumMemberId) {
        fetchSubscriptions({
          variables: {
            MemberID: netForumMemberId,
          },
        })
          .then(() => setSubscriptionAPIError(false))
          .catch(() => setSubscriptionAPIError(true))
          .finally(() => setSubscriptionsLoading(false));
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!fetchAllFunds || !fetchAllCountries || !fetchAllCurrencies) return;

      const fetchFunds = async () => {
        const result = await fetchAllFunds();
        const fetchedFunds = result?.data?.funds || [];
        setFundsData(fetchedFunds.filter((fund): fund is Fund => !!fund));
      };

      const fetchCountries = async () => {
        const result = await fetchAllCountries();
        const fetchedCountries = result?.data?.countries || [];
        setCountriesData(
          fetchedCountries.filter((country): country is Countries => !!country)
        );
      };

      const fetchCurrencies = async () => {
        const result = await fetchAllCurrencies();
        const fetchedCurrencies = result?.data?.currencies || [];
        setCurrenciesData(
          fetchedCurrencies.filter(
            (currency): currency is Currencies => !!currency
          )
        );
      };
      await Promise.all([fetchFunds(), fetchCountries(), fetchCurrencies()]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSecurityGroups = async () => {
      if (!netForumMemberId || !fetchIndividualSecurityGroups) return;
      const { data } = await fetchIndividualSecurityGroups({
        variables: {
          memberId: netForumMemberId,
        },
      });
      const secGroups = data?.securityGroups as IndividualSecurityGroupsResult[];
      const userData = {
        sec_groups: getTransformedSecurityGroups(secGroups),
      };
      setSecurityGroupsData(userData.sec_groups);
    };

    const fetchUserSubscriptions = async () => {
      if (!netForumMemberId || !fetchSubscriptions) return;

      try {
        setSubscriptionsLoading(true);
        await fetchSubscriptions({
          variables: {
            MemberID: netForumMemberId,
          },
        });
        setSubscriptionAPIError(false);
      } catch {
        setSubscriptionAPIError(true);
      } finally {
        setSubscriptionsLoading(false);
      }
    };

    fetchUserSubscriptions();
    fetchSecurityGroups();
  }, [
    netForumMemberId,
    subscriptionAPIError,
    fetchSubscriptions,
    fetchIndividualSecurityGroups,
  ]);

  useEffect(() => {
    if (!subscriptionData?.subscriptions) return;
    const filterTitleTranslation = (
      FundType: string,
      SubFundType: string,
      lang: LanguageCode
    ): FilteredTitle => {
      const fund = fundsData.find(
        f => FundType === f.fundId && SubFundType === f.fundSubId
      );
      if (!fund) return { fundTitle: null, fundId: null };

      const fundTitle = fund.fundTitle?.[lang] || null;
      const fundKey = `${fund.fundId}${fund.fundSubId}` as keyof typeof FundName;

      return {
        fundTitle,
        fundId: FundName[fundKey] || fund.id,
      };
    };

    const updateSubscriptions = (
      subscriptions: Subscriptions[]
    ): Subscriptions[] => {
      if (!Array.isArray(subscriptions)) {
        return [];
      }
      return subscriptions
        .filter(s => s.Status && s.Status.toUpperCase() === 'ACTIVE')
        .map(s => {
          const { fundTitle, fundId }: FilteredTitle = filterTitleTranslation(
            s.FundType || '',
            s.SubFundType || '',
            i18n.language.toLowerCase() as LanguageCode
          );
          return {
            ...s,
            SubFundName: fundTitle || s.FundName,
            SubWidgetData: {
              customerToken: s.Individual.MemberToken,
              fund: {
                id: fundId || 0,
              },
              country: {
                id:
                  countriesData.find(
                    c => c.name?.default === s.Address?.Country
                  )?.id || '',
              },
              currency: {
                id: currenciesData.find(c => c.code === s.Currency)?.id || '',
              },
              user: {
                authenticated: true,
                sec_groups: securityGroupsData,
                member_id: netForumMemberId,
                first_name: s.Individual.FirstName,
                last_name: s.Individual.LastName,
                localized_name: s.Individual.LocalizedName,
                phone: s.PhoneNumber,
                email: s.EmailAddress,
                address: {
                  line1: s.Address.AddressLine1,
                  line2: s.Address.AddressLine2,
                  line3: s.Address.AddressLine3,
                  city: s.Address.City,
                  state: s.Address.State,
                  zip: s.Address.PostalCode,
                  intl_province: s.Address.InternationalProvince,
                  country: s.Address.Country,
                },
              },
            },
          };
        });
    };

    if (subscriptionData?.subscriptions) {
      const updatedSubscriptions = updateSubscriptions(
        subscriptionData.subscriptions as Subscriptions[]
      );
      setSubscriptions(updatedSubscriptions);
    }
  }, [subscriptionData, fundsData, countriesData, currenciesData]);

  const handleRetry = () => {
    setSubscriptionsLoading(true);
    if (netForumMemberId) {
      fetchSubscriptions({
        variables: {
          MemberID: netForumMemberId,
        },
      })
        .then(() => setSubscriptionAPIError(false))
        .catch(() => setSubscriptionAPIError(true))
        .finally(() => setSubscriptionsLoading(false));
    }
  };

  if (
    subscriptionData?.subscriptions &&
    subscriptionData?.subscriptions[0] &&
    !subscriptionData?.subscriptions[0].SubscriptionId
  ) {
    if (!subscriptionAPIError) {
      setSubscriptionAPIError(true);
      setSubscriptionsLoading(false);
    }
  }

  if (profileError || !individualId) {
    return <p>Error loading profile data.</p>;
  }

  if (profileLoading) {
    return <Loading small />;
  }

  if (!netForumMemberId) {
    return null;
  }

  const hasSubscriptions =
    Array.isArray(subscriptions) && subscriptions.length > 0;

  const noSubscriptions =
    Array.isArray(subscriptions) && subscriptions.length === 0;

  const removeSubscription = (subscriptionId: string) => {
    const updatedSubscriptions = [...subscriptions];
    const filteredUpdatedSubscriptions = updatedSubscriptions.filter(
      s => s.SubscriptionId !== subscriptionId
    );
    setSubscriptions(filteredUpdatedSubscriptions);
  };

  return (
    <div>
      {!subscriptionsLoading &&
        hasSubscriptions &&
        !subscriptionAPIError &&
        !subscriptionsError && (
          <div className="mb-5">
            <p className="text-xs text-black mb-8 mt-2 mr-3">
              {t(
                'online-giving.my-donations-form.recurring-donations-note',
                'Note that changing the information below will not update your My Rotary profile.'
              )}
            </p>
          </div>
        )}

      {subscriptionsLoading && (
        <div data-testid="subscription-loader">
          <Loading small />
        </div>
      )}

      {!subscriptionsLoading && subscriptionAPIError && (
        <div className="mt-6 mb-6 font-bold text-black pt-4 pb-4">
          <h2 className="mb-2">
            {t('subscriptions-list.error-msg-heading', `We're sorry`)}
          </h2>
          <h3 className="mb-2">
            {t(
              'subscriptions-list.error-loading-msg',
              'Unable to load subscriptions.'
            )}
          </h3>
          <button
            type="button"
            onClick={handleRetry}
            className="bg-bright-blue-600 text-white hover:bg-dark-blue-400 focus:bg-dark-blue-400 
            active:bg-dark-blue-400 active:text-dark-blue-200 mt-2 px-4 py-2 hover:text-white active:text-white 
            font-bold uppercase justify-center text-center rounded-full"
          >
            {t('subscription-list.button-try-again', 'Try Again')}
          </button>
        </div>
      )}

      {!subscriptionsLoading &&
        noSubscriptions &&
        !subscriptionsError &&
        !subscriptionAPIError && (
          <div>
            <p>
              {t(
                'subscriptions-list.no-subscriptions-msg',
                'You do not have any active recurring donations at this time.'
              )}
            </p>
            <hr className="mb-5 mt-1 border-t-2 border-b border-gray-300" />
          </div>
        )}

      {subscriptionData?.subscriptions &&
        hasSubscriptions &&
        !subscriptionAPIError &&
        !subscriptionsLoading && (
          <ResultsList
            darkBackground
            className="-mx-8 tablet:mx-0 border-t-0"
            listId="subscriptions-results-list"
          >
            {subscriptions.map(
              item =>
                item && (
                  <ResultsListItem key={item.SubscriptionId}>
                    <SubscriptionsResult
                      SubscriptionId={item.SubscriptionId || ''}
                      SubFundName={item.SubFundName}
                      TranslatedFrequency={item.TranslatedFrequency}
                      Amount={item.Amount}
                      Frequency={item.Frequency}
                      NextChargeTimestamp={item.NextChargeTimestamp}
                      Name={
                        item.Individual?.LocalizedName
                          ? item.Individual.LocalizedName
                          : `${item.Individual?.FirstName} ${item.Individual?.LastName}`
                      }
                      PhoneNumber={item.PhoneNumber}
                      EmailAddress={item.EmailAddress}
                      Address={{
                        AddressLine1: item.Address?.AddressLine1 || '',
                        AddressLine2: item.Address?.AddressLine2 || '',
                        AddressLine3: item.Address?.AddressLine3 || '',
                        City: item.Address?.City || '',
                        State: item.Address?.State || '',
                        Country: item.Address?.Country || '',
                        PostalCode: item.Address?.PostalCode || '',
                        InternationalProvince:
                          item.Address?.InternationalProvince || '',
                      }}
                      Payment={{
                        PaymentType: item.Payment
                          ?.PaymentType as CreditCardCode,
                        MaskedCC: item.Payment?.MaskedCC || '',
                        ExpirationMonth: item.Payment?.ExpirationMonth || '',
                        ExpirationYear: item.Payment?.ExpirationYear || '',
                      }}
                      Currency={item.Currency}
                      MerchantConfig={item.MerchantConfig}
                      removeSubscription={() =>
                        removeSubscription(item.SubscriptionId)
                      }
                      WidgetData={item.SubWidgetData}
                      RequestSource={item.SubscriptionCreationSource}
                      isEditModalOpen={isEditModalOpen}
                      setIsEditModalOpen={setIsEditModalOpen}
                      handleEditModalOpen={handleEditModalOpen}
                    />
                  </ResultsListItem>
                )
            )}
          </ResultsList>
        )}

      <div className="mt-2 mb-20">
        <p className="mb-5 font-bold text-black text-sm">
          {t('subscription-list.add-new-label', 'Add a new recurring donation')}
        </p>
        <Link
          to={linkForAdd}
          className="rounded-sm px-4 py-2 text-md font-bold text-dark-blue-600 bg-orange-500 
        hover:bg-orange-400 focus:bg-orange-400 active:bg-orange-400 active:text-dark-blue-600"
        >
          {t('subscription-list.add-button-label', 'ADD')}
        </Link>
      </div>
      {isEditModalOpen && subscriptionEditRecord && (
        <EditSubscriptionModal
          onCloseEditModal={handleCloseModal}
          subscriptionEditRecord={subscriptionEditRecord}
        />
      )}
    </div>
  );
};

export default SubscriptionsList;
